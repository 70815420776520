@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

.navbar-light .navbar-nav .nav-link{
    color: white;
    font-weight:normal;
}
.sticky-nav {
    position: sticky;
    top: 0;
    z-index:1000
}
.button {
    -webkit-appearance: none;
    appearance: none;
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all .5s ease;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    border: none;
    background: #a9bacc;
    border-radius: 30px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 13px;
    cursor: cursor;
}

.colStyle {
    flex-grow:unset;
}
