@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Roboto');

.slideCaption {
    position: absolute !important;
    top: 60% !important;
    left: 30% !important;
    color: #03090f;
}
.carousel-content h2 {
    color: #03090f;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 700;
}

.mobileNo {
    padding-left: 155px;
    position: absolute;
}
 

.headerCall {
    font-size: 18px!important;
}
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

   
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

   
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

    
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .slideCaption h3 {
        font-size: 12px
    }
    .slideCaption h2 {
        font-size: 10px
    }
    .slideCaption {
        font-size: 8px
    }
    .carousel-content h2 {
        color: #03090f;
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 700;
    }
    .slideCaption {
        position: absolute !important;
        top: 50% !important;
        left: 20% !important
    }

    .about-us .content h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 60px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .about-us .content h3 {
        font-weight: 500;
        line-height: 32px;
        font-size: 15px;
    }

    .section-title h2 {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
    }

    .headerCall {
        font-size: 11px !important
    }

    .mobileNo {
        padding-left: 125px !important;
        position: absolute !important;
        padding-top: 49px !important;
    }
 
}

.btnMore {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
    display: inline-block !important;
    padding: 12px 32px !important;
    border-radius: 4px !important;
    transition: 0.5s !important;
    line-height: 1 !important;
    margin: 10px !important;
    color: #344cab !important;
    -webkit-animation-delay: 0.8s !important;
    animation-delay: 0.8s !important;
    border: 2px solid #1bbd36 !important;
}

.carousel-item {
    width: 100% !important;
   /* height: calc(100vh - 70px);*/
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}



/*.carousel-content {
    background: rgba(4, 4, 4, 0.7);
    padding: 20px;
    color: #fff;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    border-top: 5px solid #1bbd36;
    margin-bottom: 40px;
}*/



  