.drag_things_to_boxes .component_box {
    float: left;
}

.drag_things_to_boxes .box {
	border: 2px solid black;
	border-radius: 4px;
	width: 140px;
	height: 276px;
	margin: 10px 80px 0 0;
	position: relative;
}

.drag_things_to_boxes .highlighted .box {
	box-shadow: inset 0 0 4px #00f;
}
