.p-datatable .p-datatable-thead > tr > th, .p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-tfoot > tr > td {
    /*padding: .25em .5em;*/
    border: 1px;
    border-style: groove;
}
.logoText{
    font-family:'Times New Roman';
    font-weight:bold;
       
}

.p-dialog-titlebar{
    background-color:grey;
    color:black

}


.errorMessage {
    white-space: pre-wrap;
    color: red;
    text-align: justify
}

.successMessage {
    white-space: pre-wrap;
    color: black;
    text-align: justify
}

.Unpaid {
    background-color: indianred !important;
    color: white !important;
}

.spanLink:hover{
       
  text-decoration:none!important;
}
.spanLink {
    text-decoration: underline !important;
    cursor: pointer !important
}
.p-autocomplete-panel{
    z-index:10000!important;

}

